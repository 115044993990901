<template>
  <div>
    <AppHeaderDesktop />
    <AppHeaderLogo />
    <HomePageHeroBanner />
    <!-- <carrierSlider /> -->
    <products />
    <statesWeServe />
    <pricing />
    <AppFooter />
    <!-- <mainPopUp v-if="showPopUp" @closePopUp="closePopUp" /> -->
  </div>
</template>

<script>
import AppHeaderDesktop from '../components/AppHeaderDesktop.vue';
import AppHeaderLogo from '../components/AppHeaderLogo.vue';
import HomePageHeroBanner from '../components/homePageHeroBanner.vue';
import carrierSlider from '../components/carrierSlider.vue';
import products from '../components/products.vue';
import statesWeServe from '../components/statesWeServe.vue';
import pricing from '../components/pricing.vue';
import AppFooter from '../components/AppFooter.vue';
import mainPopUp from '../components/advertising_website/main_pop_up.vue';

export default {
  components: {
    AppHeaderDesktop,
    AppHeaderLogo,
    HomePageHeroBanner,
    carrierSlider,
    products,
    statesWeServe,
    pricing,
    AppFooter,
    // mainPopUp,
  },
  data() {
    return {
      // showPopUp: true,
    };
  },
};
</script>

<style>
/* Your HomePage styles here */
</style>
