<template>
  <div class="max-w-2xl mx-auto">
    <div v-if="currentStep === 1" class="mx-auto mt-4">
      <Icon
        icon="mdi:car-side"
        class="text-blue-500 mx-auto text-4xl md:text-6xl"
      />
    </div>
    <h2
      v-if="currentStep === 1"
      class="text-xl font-bold mb-6 text-center text-ietexth1"
    >
      Auto Insurance Change
    </h2>
    <component
      v-if="!formSubmitted"
      :is="currentComponent"
      @updateMasterObject="handleMasterObject"
      @update-step="updateStep"
      @update-form-data="updateFormData"
      :form-data="formData"
      @submitForm="submitFormData"
      :loading="loading"
    />
  </div>

  <div
    v-if="formSubmitted"
    class="w-full max-w-md mx-auto bg-white rounded-xl shadow-xl overflow-hidden"
  >
    <div class="bg-green-600 text-white text-center py-4">
      <h2 class="text-xl md:text-2xl font-bold">Submission Confirmed!</h2>
    </div>
    <div class="flex justify-center py-5">
      <Icon
        icon="line-md:clipboard-check-twotone"
        class="text-green-500"
        style="font-size: 3rem"
      />
    </div>
    <div class="p-6 fade-in space-y-3">
      <p
        class="text-lg md:text-xl font-semibold text-gray-800 text-center mb-4"
      >
        Your Auto Change Request is in Good Hands
      </p>
      <p class="text-center text-sm md:text-md text-gray-600 mb-2">
        We're reviewing your request and will review your policy details within
        the next 24-48 hours.
      </p>
      <p class="text-center text-sm md:text-md text-gray-600 mb-2">
        A confirmation email has been sent to
        <span class="font-semibold">{{ formData.email }}</span
        >. Please check your inbox for further instructions.
      </p>
      <p class="text-sm md:text-md text-gray-600 mb-4">
        <span class="font-bold text-center">Need immediate assistance?</span>
        <br />
        <Icon icon="carbon:phone" class="inline-block text-blue-600" />
        <a
          href="tel:+18002699137"
          class="text-center text-blue-600 hover:text-blue-800"
        >
          (800)-269-9137</a
        >
        <br />Our insurance experts are always here to help.
      </p>
      <div class="text-center">
        <a
          href="/"
          class="inline-block text-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          Return to Homepage
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import { ref, reactive, computed, onMounted } from 'vue';
import auto_insurance_form_step_1 from './auto_insurance_form_step_1.vue';
import auto_insurance_form_step_2 from './auto_insurance_form_step_2.vue';
import auto_insurance_form_step_3 from './auto_insurance_form_step_3.vue';
import confetti from 'canvas-confetti';
import axios from 'axios';

export default {
  name: 'AutoInsuranceForm',
  components: {
    Icon,
  },
  setup() {
    const formSubmitted = ref(false);
    const loading = ref(false);
    const isSubmitting = ref(false);
    const currentStep = ref(1);
    const formData = reactive({
      // Initial structure based on your provided master object structure
      selectedChangeOption: '',
      vehicles: [],
      selectedCoverages: [],
      additionalInsured: [],
      lienholdersLessors: [],
      contactInformation: {},
      // Add other initial fields if necessary
    });

    const componentsMap = {
      1: auto_insurance_form_step_1,
      2: auto_insurance_form_step_2,
      3: auto_insurance_form_step_3,
    };

    const currentComponent = computed(() => componentsMap[currentStep.value]);

    const handleMasterObject = (updatedData) => {
      // Merge the updatedData into the formData object
      Object.assign(formData, updatedData);
    };

    const updateStep = () => {
      if (currentStep.value >= 1 && currentStep.value < 3) {
        // Assuming 3 is your last step
        currentStep.value += 1; // Increment step
      }
    };

    const submitFormData = async () => {
      if (isSubmitting.value) return;
      isSubmitting.value = true;
      loading.value = true;
      
      try {
        const submitData = {
          selected_change_option: formData.selectedChangeOption,
          vehicles: formData.vehicles || [],
          selected_coverages: formData.selectedCoverages || [],
          additional_insured: formData.additionalInsured || [],
          lienholders_lessors: formData.lienholdersLessors || [],
          contact_information: {
            firstName: formData.contactInformation.firstName,
            lastName: formData.contactInformation.lastName,
            email: formData.contactInformation.email,
            phone: formData.contactInformation.phone,
            address: formData.contactInformation.address,
          },
        };

        console.log('Submitting form data:', submitData);
        const response = await axios.post(
          '/api/submit-auto-insurance-form',
          submitData,
        );
        formSubmitted.value = true;
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { x: 0.5, y: 0.5 },
        });
        console.log('Submission response:', response);
      } catch (error) {
        console.error('Submission error:', error);
      } finally {
        loading.value = false;
        isSubmitting.value = false;
      }
    };
    const updateFormData = (contactInfo) => {
      formData.contactInformation = contactInfo;
    };

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      loading,
      currentStep,
      currentComponent,
      formData,
      updateStep,
      handleMasterObject,
      updateFormData,
      formSubmitted,
      submitFormData, // Make this available for your final step
    };
  },
};
</script>
