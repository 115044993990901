<template>
  <div class="flex flex-col items-center justify-center bg-white min-h-fit">
    <div class="mb-20 z-0 md:z-10">
      <div class="flex flex-col">
        <div class="text-center mx-auto">
          <Icon
            icon="heroicons:home-solid"
            class="text-red-600 mx-auto text-4xl md:text-6xl"
          />
        </div>
        <h1
          class="text-center text-xl md:text-2xl font-bold text-gray-800 mb-2"
        >
          {{
            customerType === 'lender'
              ? 'Home Insurance Policy Change'
              : 'Home Insurance Change'
          }}
        </h1>
      </div>
      <!-- Interactive Step Bar -->
      <div v-if="!formSubmitted" class="flex justify-center space-x-4">
        <!-- Step 1: Contact Information -->
        <div
          :class="{
            'current-step': currentStep === 1,
            'active-step': currentStep > 1,
          }"
          class="step-indicator"
        >
          <span class="font-semibold">1</span>
        </div>
        <!-- Step 2: Changes -->
        <div
          :class="{
            'current-step': currentStep === 2,
            'active-step': currentStep > 2,
          }"
          class="step-indicator"
        >
          <span class="font-semibold">2</span>
        </div>
        <!-- Step 3: Submit -->
        <div
          :class="{ 'current-step': currentStep === 3 }"
          class="step-indicator"
        >
          <span class="font-semibold">3</span>
        </div>
      </div>
      <!--Success Message for form-->
      <div
        v-if="formSubmitted"
        class="w-full h-auto mx-auto rounded-lg p-6 flex flex-col items-center space-y-4"
      >
        <h2 class="text-2xl font-bold">Success!</h2>
        <img src="@/assets/images/gifs/7efs.gif" alt="Success Animation" />
        <p class="text-green-600 font-bold">Your request has been submitted</p>
        <p class="mt-[4px] text-sm text-center text-textDarkGray">
          Please give us 24-48 hours to process your request.
        </p>
        <p
          class="mt-[4px] tracking-[0.2px] leading-[20px] text-center text-sm text-[#1E182E]"
        >
          <span class="font-bold">Can't wait? </span>
          <span>Give us a call at </span>
          <a href="tel:+18002699137" class="text-[#DB0908] font-bold"
            >(800)-269-9137</a
          >
          <br />One of our Insurance Experts will be ready to assist.
        </p>
        <a
          href="/"
          class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-[180px] mb-6 mt-40 text-center"
        >
          Back to homepage
        </a>
      </div>
      <div class="max-w-2xl mx-auto bg-white p-8 rounded-xl shadow-xl">
        <!--Contact Information Step 1-->
        <div v-if="!formSubmitted && currentStep === 1">
          <h3 class="text-lg font-bold text-center mb-6 text-gray-800">
            {{
              customerType === 'lender'
                ? 'Lender Contact Information'
                : 'Contact Information'
            }}
          </h3>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                for="first-name"
                class="block text-sm font-semibold text-gray-700 mb-2"
                >First Name</label
              >
              <input
                v-model="firstName"
                type="text"
                id="first-name"
                class="capitalize w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
              <p v-if="errors.firstName" class="text-red-500 text-xs italic">
                {{ errors.firstName }}
              </p>
            </div>
            <div>
              <label
                for="last-name"
                class="block text-sm font-semibold text-gray-700 mb-2"
                >Last Name</label
              >
              <input
                v-model="lastName"
                type="text"
                id="last-name"
                class="capitalize w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
              <p v-if="errors.lastName" class="text-red-500 text-xs italic">
                {{ errors.lastName }}
              </p>
            </div>
            <div>
              <label
                for="policy-number"
                class="block text-sm font-semibold text-gray-700 mb-2 flex items-center"
              >
                Policy Number
                <div
                  @mouseover="showPopup = true"
                  @mouseleave="hidePopupWithDelay"
                  class="relative flex items-center"
                >
                  <Icon
                    icon="mdi:information-outline"
                    class="text-blue-600 ml-2 cursor-pointer"
                    style="height: 1em; width: 1em"
                  />
                  <div
                    v-if="showPopup"
                    class="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-white rounded-lg shadow-lg text-sm text-gray-700 w-64"
                    style="min-width: 250px"
                  >
                    If you do not know your policy number, use our
                    <router-link to="/policy-search" class="text-red-700"
                      >"Policy Search"</router-link
                    >
                    in the customer service section of the site.
                  </div>
                </div>
              </label>
              <input
                v-model="policyNumber"
                type="text"
                id="policy-number"
                class="uppercase w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
              <p v-if="errors.policyNumber" class="text-red-500 text-xs italic">
                {{ errors.policyNumber }}
              </p>
            </div>

            <div>
              <label
                for="phone-number"
                class="block text-sm font-semibold text-gray-700 mb-2"
                >Phone Number</label
              >
              <InputMask
                id="phone-number"
                mask="(999) 999-9999"
                v-model="phoneNumber"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
              ></InputMask>
              <p v-if="errors.phoneNumber" class="text-red-500 text-xs italic">
                {{ errors.phoneNumber }}
              </p>
            </div>
            <div class="col-span-1 md:col-span-2">
              <label
                for="email"
                class="block text-sm font-semibold text-gray-700 mb-2"
                >{{
                  customerType === 'lender' ? 'Work Email:' : 'Email'
                }}</label
              >
              <input
                id="email"
                v-model="email"
                type="email"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
              <p v-if="errors.email" class="text-red-500 text-xs italic">
                {{ errors.email }}
              </p>
            </div>
            <div class="col-span-1 md:col-span-2 flex justify-end">
              <button
                @click="goToNextStep"
                class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <!--Changes Section Step 2-->
        <div v-if="!formSubmitted && currentStep === 2">
          <h4 class="text-2xl font-bold text-center mb-6 text-ietextlight">
            What would you like to change?
          </h4>
          <div class="space-y-6">
            <div
              class="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 justify-center"
            >
              <!-- Change Type Buttons -->
              <button
                :class="{
                  'opacity-50 cursor-not-allowed':
                    changeType === 'changeInsured',
                }"
                class="flex items-center justify-center px-3 py-2 border bg-white rounded shadow hover:bg-gray-100 focus:outline-none focus:ring focus:ring-blue-500 transition"
                @click="changeType = 'coverage'"
              >
                <Icon
                  icon="mdi-home-city-outline"
                  class="text-gray-700 w-6 h-6 mr-2"
                ></Icon>
                <p>Coverage</p>
              </button>
              <button
                :class="{
                  'opacity-50 cursor-not-allowed': changeType === 'coverage',
                }"
                class="flex items-center justify-center px-3 py-2 border bg-white rounded shadow hover:bg-gray-100 focus:outline-none focus:ring focus:ring-blue-500 transition"
                @click="changeType = 'changeInsured'"
              >
                <Icon
                  icon="mdi-account-outline"
                  class="text-gray-700 w-6 h-6 mr-2"
                ></Icon>
                <p>Add Insured / Remove Insured</p>
              </button>
            </div>
            <!-- Insured Change Options -->
            <div
              v-if="changeType === 'changeInsured'"
              class="flex flex-row justify-center space-x-4"
            >
              <button
                :class="{
                  'opacity-50 cursor-not-allowed':
                    addRemoveInsured === 'Remove Insured',
                }"
                class="flex items-center justify-center px-4 py-2 border bg-white rounded shadow hover:bg-gray-100 focus:outline-none focus:ring focus:ring-blue-500 transition"
                @click="
                  addRemoveInsured !== 'Remove Insured' &&
                    (addRemoveInsured = 'Add Insured')
                "
              >
                <Icon
                  icon="material-symbols:person-add-outline"
                  class="text-gray-700 w-6 h-6 mr-2"
                ></Icon>
                <p>Add Person</p>
              </button>
              <button
                :class="{
                  'opacity-50 cursor-not-allowed':
                    addRemoveInsured === 'Add Insured',
                }"
                class="flex items-center justify-center px-4 py-2 border bg-white rounded shadow hover:bg-gray-100 focus:outline-none focus:ring focus:ring-blue-500 transition"
                @click="
                  addRemoveInsured !== 'Add Insured' &&
                    (addRemoveInsured = 'Remove Insured')
                "
              >
                <Icon
                  icon="material-symbols:person-remove-outline"
                  class="text-gray-700 w-6 h-6 mr-2"
                ></Icon>
                <p>Remove Person</p>
              </button>
            </div>
            <div v-if="addRemoveInsured === 'Add Insured'" class="space-y-4">
              <div>
                <label
                  for="insuredFirstName"
                  class="block text-sm font-semibold text-gray-700 mb-2"
                  >First Name</label
                >
                <input
                  v-model="insuredFirstName"
                  type="text"
                  id="insuredFirstName"
                  class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                <p
                  v-if="errors.insuredFirstName"
                  class="text-red-500 text-xs italic"
                >
                  {{ errors.insuredFirstName }}
                </p>
              </div>
              <div>
                <label
                  for="insuredLastName"
                  class="block text-sm font-semibold text-gray-700 mb-2"
                  >Last Name</label
                >
                <input
                  v-model="insuredLastName"
                  type="text"
                  id="insuredLastName"
                  class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                <p
                  v-if="errors.insuredLastName"
                  class="text-red-500 text-xs italic"
                >
                  {{ errors.insuredLastName }}
                </p>
              </div>
            </div>
            <div v-if="addRemoveInsured === 'Remove Insured'" class="space-y-4">
              <div>
                <label
                  for="insuredFirstName"
                  class="block text-sm font-semibold text-gray-700 mb-2"
                  >First Name</label
                >
                <input
                  v-model="insuredFirstName"
                  type="text"
                  id="insuredFirstName"
                  class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div>
                <label
                  for="insuredLastName"
                  class="block text-sm font-semibold text-gray-700 mb-2"
                  >Last Name</label
                >
                <input
                  v-model="insuredLastName"
                  type="text"
                  id="insuredLastName"
                  class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
            </div>
          </div>

          <div v-if="changeType === 'coverage'" class="space-y-4 my-4">
            <h4 class="text-lg font-semibold mb-2 text-center">
              What would you like to adjust?
            </h4>
            <div class="flex items-center justify-center gap-4">
              <!-- Coverage Adjustment Buttons -->
              <!-- Dwelling Coverage Button -->
              <button
                :class="{
                  'opacity-50 cursor-not-allowed':
                    coverageChange && coverageChange !== 'Dwelling Coverage',
                }"
                class="flex items-center justify-center p-3 border bg-white rounded shadow hover:bg-gray-100 focus:outline-none focus:ring focus:ring-blue-500 transition"
                @click="coverageChange = 'Dwelling Coverage'"
              >
                <Icon
                  icon="mdi:home-roof"
                  class="text-gray-700 w-6 h-6 mr-2"
                ></Icon>
                <span class="text-gray-700">Dwelling</span>
              </button>

              <!-- Personal Property Coverage Button -->
              <button
                :class="{
                  'opacity-50 cursor-not-allowed':
                    coverageChange &&
                    coverageChange !== 'Personal Property Coverage',
                }"
                class="flex items-center justify-center p-3 border bg-white rounded shadow hover:bg-gray-100 focus:outline-none focus:ring focus:ring-blue-500 transition"
                @click="coverageChange = 'Personal Property Coverage'"
              >
                <Icon
                  icon="mdi:couch-outline"
                  class="text-gray-700 w-6 h-6 mr-2"
                ></Icon>
                <span class="text-gray-700">Personal Property</span>
              </button>

              <!-- Deductible Button -->
              <button
                :class="{
                  'opacity-50 cursor-not-allowed':
                    coverageChange && coverageChange !== 'Deductible',
                }"
                class="flex items-center justify-center p-3 border bg-white rounded shadow hover:bg-gray-100 focus:outline-none focus:ring focus:ring-blue-500 transition"
                @click="coverageChange = 'Deductible'"
              >
                <Icon
                  icon="carbon:policy"
                  class="text-gray-700 w-6 h-6 mr-2"
                ></Icon>
                <span class="text-gray-700">Deductible</span>
              </button>
            </div>
          </div>
          <div v-if="coverageChange === 'Dwelling Coverage'" class="space-y-4">
            <h4 class="text-lg font-semibold mb-2 text-center">
              Adjust Dwelling Coverage
            </h4>
            <p class="text-center text-sm text-gray-600 mb-4">
              Select from $10,000 to $2,000,000 for customizable coverage
              options.
            </p>
            <div class="flex flex-col items-center justify-center gap-4">
              <div class="text-center text-2xl font-medium text-gray-700">
                ${{ dwellingCoverageAmount.toLocaleString() }}
              </div>
              <Slider
                v-model="dwellingCoverageAmount"
                :min="10000"
                :max="2000000"
                :step="10000"
                class="w-full md:w-1/2"
              />
            </div>
          </div>
          <div
            v-if="coverageChange === 'Personal Property Coverage'"
            class="space-y-4"
          >
            <h4 class="text-lg font-semibold mb-2 text-center">
              Adjust Personal Property Coverage
            </h4>
            <p class="text-center text-sm text-gray-600 mb-4">
              Select from $10,000 to $2,000,000 for customizable coverage
              options.
            </p>
            <div class="flex flex-col items-center justify-center gap-4">
              <div class="text-center text-2xl font-medium text-gray-700">
                ${{ personalPropertyCoverageAmount.toLocaleString() }}
              </div>
              <Slider
                v-model="personalPropertyCoverageAmount"
                :min="10000"
                :max="2000000"
                :step="10000"
                class="w-full md:w-1/2"
              />
            </div>
          </div>
          <div v-if="coverageChange === 'Deductible'" class="space-y-4">
            <h4 class="text-lg font-semibold mb-2 text-center">
              Adjust Deductible
            </h4>
            <p class="text-center text-sm text-gray-600 mb-4">
              Choose deductibles ranging from $500 to $5,000 to balance your
              costs.
            </p>
            <div class="flex flex-col items-center justify-center gap-4">
              <div class="text-center text-2xl font-medium text-gray-700">
                ${{ selectedDeductible.toLocaleString() }}
              </div>
              <Slider
                v-model="selectedDeductible"
                :min="500"
                :max="5000"
                :step="500"
                class="w-full md:w-1/2"
              />
            </div>
          </div>
          <div class="flex justify-between mt-8">
            <button
              @click="goToPreviousStep"
              class="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Back
            </button>
            <button
              @click="goToNextStep"
              class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Next
            </button>
          </div>
        </div>
        <!--Final Step-->
        <div v-if="!formSubmitted && currentStep === 3">
          <div class="space-y-6">
            <h3 class="text-2xl font-bold text-center mb-6 text-gray-800">
              Review & Finalize Submission
            </h3>

            <!-- Selection Review Section -->
            <div class="mb-8 p-4 border border-gray-200 rounded-md">
              <h4 class="text-lg font-semibold mb-4 text-gray-800">
                Your Selections:
              </h4>
              <div class="space-y-2">
                <p>
                  <strong>Change Type:</strong>
                  {{
                    changeType === 'coverage'
                      ? 'Adjust Coverage'
                      : 'Update Insured'
                  }}
                </p>
                <p v-if="changeType === 'changeInsured'">
                  <strong>Action:</strong> {{ addRemoveInsured }}
                </p>
                <p v-if="changeType === 'changeInsured'">
                  <strong>First Name:</strong> {{ insuredFirstName }}
                </p>
                <p v-if="changeType === 'changeInsured'">
                  <strong>Last Name:</strong> {{ insuredLastName }}
                </p>
                <p v-if="changeType === 'coverage'">
                  <strong>Coverage Type:</strong> {{ coverageChange }}
                </p>
                <p v-if="coverageChange === 'Dwelling Coverage'">
                  <strong>Selected Amount:</strong> ${{
                    dwellingCoverageAmount.toLocaleString()
                  }}
                </p>
                <p v-if="coverageChange === 'Personal Property Coverage'">
                  <strong>Selected Amount:</strong> ${{
                    personalPropertyCoverageAmount.toLocaleString()
                  }}
                </p>
                <p v-if="coverageChange === 'Deductible'">
                  <strong>Selected Amount:</strong> ${{
                    selectedDeductible.toLocaleString()
                  }}
                </p>
              </div>
            </div>

            <div v-if="(customerType = 'lender')" class="mt-6">
              <div
                :class="{
                  'bg-green-500': documentUploaded,
                  'bg-white': !documentUploaded,
                }"
                class="py-6 px-4 rounded-lg shadow-sm"
              >
                <div>
                  <!-- Completed Label -->
                  <div v-if="documentUploaded" class="mb-4">
                    <span
                      class="text-green-600 bg-white py-1 px-3 rounded-full custom-scale custom-fadeIn"
                      >Completed</span
                    >
                  </div>

                  <!-- Heading -->
                  <h2
                    :class="{
                      'text-white': documentUploaded,
                      'text-gray-900': !documentUploaded,
                    }"
                    class="text-lg font-bold mb-4"
                  >
                    Upload your borrower's authorization form
                  </h2>
                  <!-- Secondary heading -->
                  <h3
                    :class="{
                      'text-white': documentUploaded,
                      'text-gray-600': !documentUploaded,
                    }"
                    class="text-sm md:text-md text-gray-700 my-2"
                  >
                    PDF, DOC, DOCX, JPG, JPEG, PNG are accepted file types
                  </h3>

                  <!-- Upload Success Message -->
                  <p v-if="documentUploaded" class="text-white custom-fadeIn">
                    Uploaded: <br /><span class="font-medium">{{
                      uploadedFileName
                    }}</span
                    ><br />
                    (Upload Successful)
                  </p>

                  <div v-if="!documentUploaded">
                    <div class="mt-6">
                      <label
                        class="block text-md md:text-lg text-gray-700 font-medium mb-3 cursor-pointer"
                        for="document-upload"
                      >
                        Upload Document
                      </label>
                      <!-- File Upload Area -->
                      <div class="flex items-center justify-center w-full">
                        <input
                          type="file"
                          class="hidden"
                          id="document-upload"
                          accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                          @change="handleFileChange"
                          ref="fileInput"
                        />
                        <label
                          for="document-upload"
                          class="flex flex-col items-center justify-center w-full h-24 md:h-32 p-4 md:p-6 rounded-lg border-4 border-dashed cursor-pointer hover:border-blue-500 transition-colors text-center"
                        >
                          <p class="text-gray-700 text-md md:text-lg">
                            {{ fileLabel }}
                          </p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Message Section -->
            <section>
              <label
                for="message"
                class="block text-gray-700 text-sm font-semibold mb-2"
                >Your Message</label
              >
              <textarea
                v-model="message"
                id="message"
                rows="4"
                placeholder="Add any additional information here..."
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
              ></textarea>
            </section>

            <!-- Navigation Buttons -->
            <div class="flex justify-between items-center mt-8">
              <button
                @click="goToPreviousStep"
                :disabled="isSubmitting"
                class="px-6 py-2 border border-gray-300 text-gray-700 rounded hover:border-gray-400 transition duration-300"
                :class="{ 'opacity-50 cursor-not-allowed': isSubmitting }"
              >
                Back
              </button>
              <button
                @click="submitForm"
                :disabled="isSubmitting"
                class="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300 flex items-center space-x-2"
                :class="{ 'opacity-50 cursor-not-allowed': isSubmitting }"
              >
                <span>{{ isSubmitting ? 'Submitting...' : 'Submit' }}</span>
                <span v-if="isSubmitting" class="inline-block animate-spin">↻</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Background Images -->
    <div class="hidden md:flex justify-between w-full mt-[-300px]">
      <img
        src="@/assets/images/productHeroBanners/homeinsurance/homeinsuranceHeroBannerLeft.svg"
        class="w-1/3"
      />
      <div class="flex-grow"></div>
      <img
        src="@/assets/images/productHeroBanners/homeinsurance/homeinsuranceHeroBannerRight.svg"
        class="w-1/3"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { Icon } from '@iconify/vue';
import { useForm, useField, ErrorMessage } from 'vee-validate';
import * as yup from 'yup'; // for validation schema
import InputMask from 'primevue/inputmask';
import Slider from 'primevue/slider';
import axios from 'axios';
import confetti from 'canvas-confetti';

export default {
  name: 'homeInsuranceChange',
  props: {
    customerType: {
      type: String,
      required: true,
    },
  },
  components: {
    Icon,
    InputMask,
    ErrorMessage,
    Slider,
  },
  setup(props) {
    const insuredFirstName = ref(null);
    const insuredLastName = ref(null);
    const addRemoveInsured = ref('');
    const selectedDeductible = ref(2000); // Start with the first value
    const dwellingCoverageAmount = ref(300000); // Starting coverage amount
    const personalPropertyCoverageAmount = ref(150000); // Starting coverage amount
    const selectedFile = ref(null);
    const uploadedFileName = ref('');
    const documentUploaded = ref(false);
    const handleFileChange = (event) => {
      if (event.target.files.length > 0) {
        selectedFile.value = event.target.files[0];
        documentUploaded.value = true; // Set documentUploaded to true when a file is selected
        uploadedFileName.value = event.target.files[0].name; // Update the name of the uploaded file
      }
    };
    const currentStep = ref(1);
    const goToNextStep = async () => {
      if (currentStep.value === 1) {
        // Validation logic for Step 1
        const { valid, errors } = await validate();
        if (valid) {
          currentStep.value = 2; // Move to Step 2
        } else {
          console.error('Form is invalid', errors);
        }
      } else if (currentStep.value === 2) {
        // Logic for moving from Step 2 to Step 3
        if (changeType.value === 'changeInsured') {
          if (
            insuredFirstName.value !== null &&
            insuredLastName.value !== null
          ) {
            currentStep.value = 3; // Move to Step 3
          } else {
            console.error('Insured first name and last name cannot be null');
          }
        } else if (changeType.value === 'coverage') {
          if (coverageChange.value !== null) {
            currentStep.value = 3; // Move to Step 3
          } else {
            console.error('Coverage change cannot be null');
          }
        }
      }
    };
    const goToPreviousStep = () => {
      if (currentStep.value === 3) {
        // Logic for moving back to Step 2
        currentStep.value = 2;
      } else if (currentStep.value === 2) {
        // Logic for moving back to Step 1
        // Reset specific values as you go back to Step 1
        addRemoveInsured.value = null;
        changeType.value = null;
        coverageChange.value = null;

        currentStep.value = 1; // Move back to Step 1
      }
    };
    // Define validation schema
    const schema = yup.object({
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      phoneNumber: yup
        .string()
        .required('Phone number is required')
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          'Phone number must be (XXX) XXX-XXXX',
        ),
      email: yup
        .string()
        .required('Email is required')
        .email('Invalid email format'),
      policyNumber: yup.string().required('Policy number is required'),
    });
    // Setup form with vee-validate
    const { handleSubmit, errors, validate } = useForm({
      validationSchema: schema,
    });
    const showPopup = ref(false);
    const popupHideDelay = ref(800);
    const hidePopupWithDelay = () => {
      setTimeout(() => {
        showPopup.value = false;
      }, popupHideDelay.value); // Use the delay constant here
    };
    const changeType = ref(null);
    const coverageChange = ref(null);
    const coverageAmount = computed(() => {
      if (coverageChange.value === 'Dwelling Coverage') {
        return dwellingCoverageAmount.value;
      } else if (coverageChange.value === 'Personal Property Coverage') {
        return personalPropertyCoverageAmount.value;
      }
      return null; // or a default value
    });

    // Define fields
    const { value: firstName } = useField('firstName');
    const { value: lastName } = useField('lastName');
    const { value: phoneNumber } = useField('phoneNumber');
    const { value: email } = useField('email');
    const { value: policyNumber } = useField('policyNumber');
    const { value: message } = useField('message');
    const fileInput = ref(null);
    const formSubmitted = ref(false);
    const isSubmitting = ref(false);

    const submitForm = handleSubmit(async (values) => {
      if (isSubmitting.value) return;
      isSubmitting.value = true;
      
      try {
        const formData = new FormData();
        formData.append('customer_type', props.customerType);
        formData.append('FirstName', firstName.value);
        formData.append('LastName', lastName.value);
        formData.append('PhoneNumber', phoneNumber.value);
        formData.append('Email', email.value);
        formData.append('PolicyNumber', policyNumber.value);
        formData.append('ChangeType', changeType.value);
        formData.append('AddRemoveInsured', addRemoveInsured.value);
        formData.append('InsuredFirstName', insuredFirstName.value);
        formData.append('InsuredLastName', insuredLastName.value);
        formData.append('CoverageChange', coverageChange.value);
        formData.append(
          'CoverageAmount',
          coverageChange.value === 'Dwelling Coverage'
            ? dwellingCoverageAmount.value
            : personalPropertyCoverageAmount.value,
        );
        formData.append('SelectedDeductible', selectedDeductible.value);
        formData.append('Message', message.value);
        // Append other fields if necessary

        if (selectedFile.value) {
          formData.append('document', selectedFile.value);
        }

        // Make the POST request to your backend
        const response = await axios.post('/api/insurance-requests', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Form submitted successfully', response.data);
        formSubmitted.value = true; // Set form as submitted
        // Trigger confetti animation
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { x: 0.5, y: 0.5 },
        });
      } catch (error) {
        console.error('Error submitting form', error);
      } finally {
        isSubmitting.value = false;
      }
    });

    const fileLabel = computed(() => {
      if (selectedFile.value) {
        return selectedFile.value.name;
      }
      return 'Click or drag file to this area to upload';
    });

    return {
      currentStep,
      addRemoveInsured,
      goToNextStep,
      goToPreviousStep,
      popupHideDelay,
      hidePopupWithDelay,
      firstName,
      lastName,
      insuredFirstName,
      insuredLastName,
      showPopup,
      phoneNumber,
      email,
      policyNumber,
      coverageChange,
      dwellingCoverageAmount,
      personalPropertyCoverageAmount,
      selectedDeductible,
      documentUploaded,
      uploadedFileName,
      fileLabel,
      selectedFile,
      message,
      changeType,
      fileInput,
      formSubmitted,
      submitForm,
      errors,
      handleFileChange,
      isSubmitting,
    };
  },
};
</script>

<style>
.form-input {
  background-color: white;
  border: 1px solid #dddfe2;
  border-radius: 0.375rem; /* 6px */
  padding: 0.5rem 0.75rem; /* 8px 12px */
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}

.form-input:focus {
  border-color: #cbd5e0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.button {
  transition: background-color 0.3s ease-in-out;
}
.button:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  border: 1px solid #dddfe2;
  background-color: white;
  background-position: right 0.5rem center;
  background-size: 1.25em 1.25em;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.placeholder-option {
  color: #a0aec0; /* Tailwind's gray-400, adjust as needed */
}

.form-select:focus {
  border-color: #cbd5e0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.step-indicator {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ddd; /* Default background color */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.5s ease,
    transform 0.5s ease;
}

.active-step {
  background-color: #007bff; /* Active step color */
}

.current-step {
  background-color: #007bff; /* Current step color */
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.fade-in {
  animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
